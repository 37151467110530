export default [
  {
    value: "createdAt",
    text: "Created At",
    sortable: true,
    filterable: true,
    width: 140,
  },
  {
    value: "exid",
    text: "Camera ID",
    sortable: true,
    filterable: true,
  },
  {
    value: "sharerFullname",
    text: "Sharer",
    sortable: true,
    filterable: true,
  },
  {
    value: "shareeFullname",
    text: "Sharee",
    sortable: true,
    filterable: true,
  },
  {
    value: "shareeEmail",
    text: "Sharee Email",
    sortable: true,
    filterable: true,
  },
  {
    value: "message",
    text: "Message",
    width: 400,
    sortable: true,
    filterable: true,
  },
]
