<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="shares"
          show-select
          item-key="id"
          :headers="headers"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn
              dark
              color="error"
              small
              :disabled="!reportStore.selectedItems.length"
              @click="deleteAll"
            >
              Delete
            </v-btn>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template #item.sharerFullname="{ item }">
            {{ item.sharerFullname }}
          </template>
          <template #item.shareeFullname="{ item }">
            {{ item.shareeFullname }}
          </template>
          <template #item.message="{ item }">
            <EReadMore :content="item.message" :max-length="60" />
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import SharesHeader from "@/components/shares/sharesHeaders"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  components: {
    ReportTable,
  },
  data: () => {
    return {
      headers: SharesHeader,
      filterFields: {
        exid: {
          component: "TextFieldSearchFilter",
        },
        sharerFullname: {
          component: "TextFieldSearchFilter",
        },
        shareeFullname: {
          component: "TextFieldSearchFilter",
        },
        shareeEmail: {
          component: "TextFieldSearchFilter",
        },
      },
    }
  },
  head() {
    return {
      title: "Admin - Shares",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.shares.getShares
    },
  },
  methods: {
    async deleteAll() {
      if (
        await this.$confirmDialog.open({
          title: "Delete Share(s)",
          message: `Are you sure you want to delete ${this.reportStore.selectedItems.length} selected camera shares ?`,
        })
      ) {
        try {
          await AdminApi.shares.deleteShares({
            ids: this.reportStore.selectedItems.map((share) => share.id),
          })
          this.reportStore.items = this.reportStore.items.filter(
            (item) => !this.reportStore.selectedItems.includes(item)
          )
          this.reportStore.selectedItems = []
          this.$notifications.success("Camera share(s) has been deleted!")
        } catch (error) {
          this.$notifications.error({ text: "Delete Share failed!", error })
        }
      }
    },
  },
}
</script>
